<template>
  <div class="side-panel side-panel-left" ref="scrollContainer">
    <div class="side-panel-header top-sticky-blur top-sticky-blur-neutral d-none-pseudo px-0 pb-0" >
      <!--:style="{ height: avatarSize + 'px', width: headerWidth + 'px' }"-->
      <user-avatar
        :value="photoUrl"
        editable
        :scroll="scrollTop"
        size="xl"
        variant="default"
        url="/settings/photo"
        name="photo"
        @upload="$store.dispatch('auth/fetchUser')" />
      <div class="account-settings-meta">
        <h3 :style="{ fontSize: titleSize + 'px'}" class="txt-bold mt-2 mb-0 ph-no-capture">{{ user.name }}</h3>
        <p :style="{ fontSize: emailSize + 'px'}" class="txt-body txt-muted ph-no-capture">{{ user.email }}</p>
        <p class="txt-body txt-bold txt-muted" :style="{opacity: labelOpacity, maxHeight: labelHeight + 'px'}">{{ currentPlanLabel }}</p>
      </div>
      <hr class="mb-0 mt-3" :style="{opacity: 1 - labelOpacity}"/>
    </div>
    <div class="side-panel-body">
      <template v-for="group in childRoutes">
        <h4 class="txt-heading-small txt-muted pt-2 my-3">{{ group.title }}</h4>
        <div class="side-panel-widget">
          <div class="side-panel-option-wrap">
            <template v-for="(r, index) in group.routes">
              <div :key="r.label + '-option'" :id="r.label + '-option'">
                <side-panel-option
                  @click.native.stop="r.locked ? false : r.onClick ? r.onClick() : $router.push({name: r.route})"
                  :link="{name: r.route}"
                  :active="(r.route === $route.name || $route.meta && r.route === $route.meta.parent || r.route === 'AccountSubscription' && $route.name === 'AccountAddons') && !r.onClick"
                  :label="r.label"
                  :description="r.description"
                  :icon-left="r.icon"
                  :icon-right="getRightIcon(r)"
                  :option-index="index"
                  :line-indicator="true"
                  :is-lock="r.locked"
                  :tooltip="r.locked && !r.tooltipAction"
                  :custom-right="r.route === 'AccountWebhooks' && $cant('webhooks')"
                  >
                  <template v-slot:tooltip>
                    <span v-if="r.locked && !r.tooltipAction && r.route !== 'AccountWebhooks'">{{ r.tooltip ? r.tooltip : 'Coming soon' }}</span>
                  </template>
                  <template #right >
                    <upgrade-button class="ml-auto" />
                  </template>
                </side-panel-option>
              </div>
              <b-tooltip v-if="r.locked && !!r.tooltipAction" custom-class="tooltip-no-wrap" variant="ss" :target="r.label + '-option'" triggers="hover" boundary="viewport">
                <button class="tooltip-cta txt-white" @click="r.tooltipAction">
                  <span class="tooltip-cta-inner">{{ r.tooltip }}</span>
                  <span class="btn-icon ml-2 p-0">
                      <svg class="icon-regular" viewBox="0 0 24 24"><use xlink:href="#icon-chevron-right"></use></svg>
                    </span>
                </button>
              </b-tooltip>
              <hr class="my-0" v-if="index < group.routes.length - 1" :key="r.label + '-hr'"/>
            </template>
          </div>
        </div>
      </template>
    </div>
    <b-btn variant="delete" block @click="$store.dispatch('auth/logout', $router)">
      <svg viewBox="0 0 24 24" class="btn-left"><use xlink:href="#icon-exit-right"></use></svg>
      Logout
    </b-btn>
  </div>
</template>
<script>
  import get from 'lodash/get';
  import SidePanelOption from "@app2/core/Components/Sidepanel/Option";
  import SidePanelTitle from "@app2/core/Components/Sidepanel/Title";
  import UserAvatar from "@app2/core/Components/UserAvatar";
  import axios from "@app2/api/axios";
  import subscriptionMixin from "@app2/modules/AccountSettings/Pages/Routes/Subscription/Helpers/subscription.mixin.js";
  import UpgradeButton from '@app2/core/Components/UpgradeButton.vue';
  import _debounce from 'lodash/debounce';

  export default {
    name: 'HubSettingsSidenav',
    mixins: [subscriptionMixin],
    components: {
      UserAvatar,
      SidePanelOption,
      SidePanelTitle,
      UpgradeButton,
    },
    data() {
      return {
        scrollTop: 0,
      }
    },
    computed: {
      titleSize() {
        return Math.round(27 - this.scrollTop * 0.0463);
      },
      emailSize() {
        return 14 - this.scrollTop * 0.0277;
      },
      labelOpacity() {
        return 1 - this.scrollTop * 0.025;
      },
      labelHeight() {
        return 23 - this.scrollTop * 0.213;
      },
      photoUrl () {
        return this.$store.state.auth.user?.photo_url
      },
      user() {
        return this.$store.getters['auth/GET_USER'];
      },
      childRoutes() {
        let routes = [
          {
            title: 'Account',
            routes: [
              {
                label: 'Profile',
                description: 'Manage account info.',
                icon: 'user-single',
                route: 'AccountProfile',
              },
              {
                label: 'Security',
                description: 'Change password.',
                icon: 'lock-tick',
                route: 'AccountSecurity',
              },
              {
                label: 'Data',
                description: 'Manage account data.',
                icon: 'user-data',
                route: 'AccountData',
              },
            ]
          },
          {
            title: 'Billing',
            routes: [
              {
                label: 'Subscription',
                description: 'Update your subscription.',
                icon: 'money',
                route: 'AccountSubscription',
              },
              {
                label: 'Payments',
                description: 'Update your payment details.',
                icon: 'credit-card-single',
                onClick: () => {
                  this.openManagePaymentsModal();
                },
                locked: this.isUsingSparkAdapter,
                tooltip: this.isUsingSparkAdapter ? 'We are moving to a new billing system in order to serve you better. Please contact support at help@membership.io to make changes to your current subscription. Changes to your subscription may take up to 3 business days to process at this time. Thanks for your patience.' : false
              },
              {
                label: 'Invoices',
                description: 'View and download your invoice history.',
                icon: 'page-multiple',
                route: 'AccountInvoices',
              },
            ]
          },
          {
            title: 'Content',
            routes: [
              {
                label: 'Transcripts',
                description: 'Set a default language and build your custom vocabulary.',
                icon: 'transcriptions',
                route: 'AccountTranscripts',
              },
              {
                label: 'Player',
                description: 'Configure the look and feel of your media player.',
                icon: 'video-player',
                route: 'AccountPlayer',
              },
            ]
          },
        ]
          routes.push({
            title: 'Developers',
            routes: [
              {
                label: 'Webhooks',
                description: 'Configure endpoints to be notified about events in your Searchie account.',
                icon: 'unplug',
                route: 'AccountWebhooks',
                locked: this.$cant('webhooks'),
                tooltip: false,
              },
            ]
          });
        return routes;
      }
    },
    methods: {
      getRightIcon(r) {
        if(this.$cant('webhooks') && r.route === 'AccountWebhooks') return false;
        if(r.onClick) return 'arrow-right-top';
        return 'arrow-right';
      },
      handleScroll(event) {
        if(event.target.scrollTop < 108) {
          this.scrollTop = event.target.scrollTop;
        } else {
          this.scrollTop = 108;
        }
      }
    },
    mounted() {
      // Access the scroll container element using the ref
      const scrollContainer = this.$refs.scrollContainer;

      // Debounce the handleScroll method
      const debouncedScrollHandler = _debounce(this.handleScroll, 10);

      // Add scroll event listener to the scroll container
      scrollContainer.addEventListener('scroll', debouncedScrollHandler);
    },
    beforeDestroy() {
      // Access the scroll container element using the ref
      const scrollContainer = this.$refs.scrollContainer;

      // Remove the scroll event listener before component is destroyed
      scrollContainer.removeEventListener('scroll', this.handleScroll);
    },
  }
</script>
